body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'ToyotaType';
    font-weight: normal;
    src: url(./assets/fonts/ToyotaType-Regular.woff2);
}

@font-face {
    font-family: 'ToyotaType';
    font-weight: bold;
    src: url(./assets/fonts/ToyotaType-Bold.woff2);
}

@font-face {
    font-family: 'ArialCondense';
    font-weight: bold;
    src: url(./assets/fonts/Arial_Condensed_Bold.ttf);
}
